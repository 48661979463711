<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
      <!-- Card stats -->
    </base-header>

    <b-container fluid class="mt--7">
      <b-row>
        <b-col>
          <b-overlay :show="loading" rounded="md">
            <b-card no-body>
              <b-card-header class="border-0">
                <h3 class="mb-0">ワークフロー一覧</h3>
              </b-card-header>
              <!-- Main table element -->
              <b-table
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                stacked="md"
                show-empty
                small
                hover
              >
                <template #cell(name)="row">
                  {{ row.value }}
                </template>
                <template #cell(is_active)="row">
                  <b-icon v-if="row.value" icon="check-circle-fill" variant="success"></b-icon>
                  <b-icon v-else icon="x-circle-fill" variant="danger"></b-icon>
                </template>
                <template #cell(actions)="row">
                  <b-button size="sm" variant="link" :to="{path: `/workflow/workflows/${row.item.code}/`}">
                    <b-icon icon="pencil-square" variant="primary"></b-icon>
                  </b-button>
                </template>
              </b-table>
              <b-card-footer>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="md"
                ></b-pagination>
              </b-card-footer>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12">
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ajax, common, config } from '../../utils/index';
import { helper } from '../common';

export default {
  data() {
    return {
      loading: true,
      items: [],
      currentPage: 1,
      perPage: config.rowsPerPage,
    }
  },
  watch: {
    items: function(newItem, oldItem) { // eslint-disable-line
      this.loading = true;
    },
  },
  computed: {
    ...mapGetters('account', ['hasPerm']),
    fields: function() {
      const items = [
        { key: 'name', label: '名称' },
        { key: 'is_active', label: '有効', class: 'text-center' },
      ];
      if (this.hasPerm('workflow.change_workflow')) {
        items.push({ key: 'actions', label: 'Actions', class: 'text-center' });
      }
      return items;
    },
    totalRows: function() {
      return this.items.length;
    },
  },
  created: function () {
    document.title = common.getPageName(this.$route.name);
    this.getWorkflowList();
  },
  methods: {
    getWorkflowList: function() {
      const vm = this;
      ajax.fetchGet(config.api.workflow.workflow_list).then(res => {
        vm.items = res.results;
      }).catch(errors => {
        helper.show_errors(errors);
      }).finally(() => {
        vm.loading = false;
      });
    },
  },
}
</script>